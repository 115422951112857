<template>
    <div class="user-profile">
        <div class="box">
            <h2>รหัสผ่าน</h2>

            <div class="form profile"  >
                <div class="input-field">
                    <label>รหัสผ่านปัจจุบัน</label>
                    <div class="group-field">
                        <input type="password"
                            maxlength="50"
                            v-model="form.oldPassword"
                            placeholder="รหัสผ่านปัจจุบัน"
                            class="square" 
                            :class="{ 'invalid': error.oldPassword }" />
                        <router-link :to="routerPath.FORGET_PASSWORD" title="ลืมรหัสผ่าน">ลืมรหัสผ่าน</router-link>
                    </div>
                </div>
                <gadget-policy-input class="mt-3 mb-3"></gadget-policy-input>
                <div class="input-field">
                    <label>รหัสผ่านใหม่</label>
                    <input type="password"
                        maxlength="50"
                        v-model="form.newPassword"
                        placeholder="ตั้งรหัสผ่าน"
                        class="square" 
                        :class="{ 'invalid': error.newPassword }" />
                </div>
                <div class="input-field">
                    <label>ยืนยันรหัสผ่านใหม่</label>
                    <input type="password"
                        maxlength="50"
                        v-model="form.confirmPassword"
                        placeholder="ยืนยันรหัสผ่าน"
                        class="square" 
                        :class="{ 'invalid': error.confirmPassword }" />
                </div>
                <div class="action mt-3">
                    <button class="btn btn-main square" @click="changePassword()">เปลี่ยนรหัสผ่าน</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RouterPath from '@/router/path';
import Helper from '@/services/helper';
import UserService from '@/services/userService';

export default {
    data() {
        return {
            routerPath: RouterPath,
            form: this.initForm(),
            error: this.initError()
        };
    },
    methods: {
        initForm() {
            return {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            };
        },
        initError() {
            return {
                oldPassword: false,
                newPassword: false,
                confirmPassword: false
            };
        },
        async changePassword() {
            if (this.validatePassword()) {
                let loader = this.$modalLoader.render();
                const result = await UserService.changePassword(this.form);
                loader.hide();

                if (result.data?.success) {
                    this.$swal(
                        Helper.completeAlert('เปลี่ยนรหัสผ่านใหม่สำเร็จแล้ว', 'ท่านสามารถเข้าสู่ระบบด้วยรหัสผ่านใหม่ได้ทันที')
                    );
                } else {
                    this.$swal(Helper.warningAlert('เปลี่ยนรหัสผ่านใหม่ไม่สำเร็จ', result.data?.message));
                }
            }
        },
        validatePassword() {
            this.error = this.initError();
            let isValid = true;
            let isBlank = false;
            let isNotMatchPassword = false;
            let isInvalidPassword = false;

            // Check Blank
            if (!this.form.oldPassword?.trim()) {
                this.error.oldPassword = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.newPassword?.trim()) {
                this.error.newPassword = true;
                isValid = false;
                isBlank = true;
            }

            if (!this.form.confirmPassword?.trim()) {
                this.error.confirmPassword = true;
                isValid = false;
                isBlank = true;
            }

            // Compare Password
            if (this.form.newPassword !== this.form.confirmPassword) {
                this.error.confirmPassword = true;
                isValid = false;
                isNotMatchPassword = true;
            }

            // Check Password Format
            const passwordPolicy = Helper.validatePasswordFormat(this.form.newPassword);

            if (!passwordPolicy.isValid) {
                this.error.newPassword = true;
                isValid = false;
                isInvalidPassword = true;
            }

            // Popup
            const title = 'เปลี่ยนรหัสผ่านไม่สำเร็จ';

            if (isBlank) {
                this.$swal(Helper.warningAlert(title, 'โปรดกรอกรหัสผ่านและยืนยืนให้ถูกต้อง'));
            } else if (isNotMatchPassword) {
                this.$swal(Helper.warningAlert(title, 'ยืนยันรหัสผ่านไม่ถูกต้อง'));
            } else if (isInvalidPassword) {
                this.$swal(Helper.warningAlert('รหัสผ่านไม่ตรงตามเงื่อนไขด้านล่าง', '', Helper.getWarningPolicyMessage(passwordPolicy.message)));

            }

            return isValid;
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/ProfileSetting.scss';
</style>