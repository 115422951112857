<template>
    <user-base-layout>
		<template slot="user-profile">
			<user-password></user-password>
		</template>
	</user-base-layout>
</template>

<script>
import Helper from '@/services/helper';
import UserBaseLayout from '@/views/users/BaseLayout';
import UserPassword from '@/components/user/Password';


export default {
	components: {
		UserBaseLayout,
		UserPassword
	},
	metaInfo() {
		const title = 'เปลี่ยนรหัสผ่าน';
		const desc = 'เปลี่ยนรหัสผ่านสำหรับสมาชิก Yellowtire';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
                { vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
                { vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/components/user/style/ProfileSetting.scss';
</style>